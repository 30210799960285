<template>
	<section ref="newsletterFormRef" v-if="newsletterData.length > 0" v-bind="moduleTrackingData" class="u-article-newsletter mb-10">
		<div class="u-article-newsletter__content">
			<NewsletterForm class="u-newsletter__form" v-bind="newsletterForm" @error="showError" @submit="handleSubmit"> </NewsletterForm>
		</div>
	</section>
</template>

<script setup lang="ts">
	import { ref, reactive } from "vue";
	import type { INewsletterForm } from "~/components/NewsletterForm.vue";
	import NewsletterForm from "~/components/NewsletterForm.vue";

	const url = useRequestURL();
	const isLoggedIn = useUserIsLoggedIn();
	const config = useClientSideConfig();
	const userData = useUserDataFromCookie() || {};

	const props = withDefaults(
		defineProps<{
			newsletterForm?: INewsletterForm;
			title?: string;
			description?: string;
			cta?: string;
			subscriptionSource?: string;
			userdata?: any;
			audience?: string;
			ressorts?: any[];
		}>(),
		{
			title: "",
			description: "",
			cta: "Jetzt kostenlos anmelden",
			subscriptionSource: "NL-Anmeldung Inline-Element Artikel",
			userdata: {},
		},
	);

	const newsletterData = ref([]);
	const { data: newsletter } = await useFetch("/api/newsletter");
	newsletterData.value = await getNewsletterData(newsletter.value);
	const newsletterFormRef = ref();
	const newsletterForm = reactive({
		countAbos: -1,
		formAgreementText: config?.newsletterAgreementText,
		formInfoText: config?.newsletterTextHint,
		loggedIn: isLoggedIn,
		newsletterCardAbos: [""],
		userData: {
			salutation: userData?.salutation,
			firstName: userData?.firstname,
			lastName: userData?.lastname,
			email: userData?.email,
		},
	});
	const moduleTrackingData = computed(() => {
		const title = props.newsletterForm?.formTitle;
		const componentsTrackingData = useTrackComponents([
			{
				component: "article-newsletter",
				content: {
					title: title || "Artikel Newsletter",
				},
			},
		]);

		return componentsTrackingData[0].content;
	});

	if (newsletterData.value.length > 0) {
		newsletterForm.formTitle = newsletterData.value[0]?.title;
		newsletterForm.formText = newsletterData.value[0]?.teaser;
		newsletterForm.formKey = newsletterData.value[0]?.salescloud_key;
		newsletterForm.newsletterIcon = "/static/images/newsletter/" + newsletterData.value[0]?.salescloud_key + ".svg";
		newsletterForm.newsletterNotificationTime = newsletterData.value[0]?.interval;
	}

	function getNewsletter(): string[] {
		const audienceMapping: any = config?.audience_newsletter_mapping;
		const ressortsMapping: any = config?.ressort_newsletter_mapping;
		let newsletter = [];

		if (typeof props.audience !== "undefined" && audienceMapping[props.audience]) {
			newsletter.push(audienceMapping[props.audience]);
		}

		if (props.ressorts && newsletter.length === 0) {
			const firstMatchingRessort = props.ressorts.find((item) => {
				const ressortName = item?.metadata?.title;
				return ressortsMapping[ressortName] !== undefined;
			});

			if (firstMatchingRessort) {
				const firstRessortName = firstMatchingRessort.metadata?.title;
				const mappedValue = ressortsMapping[firstRessortName];
				if (mappedValue) {
					newsletter.push(mappedValue);
				}
			}
		}

		return newsletter.length > 0 ? newsletter : [""];
	}

	function getNewsletterData(newsletterList: any) {
		return newsletterList.filter((item: any) => {
			return getNewsletter().includes(item.salescloud_key);
		});
	}

	function handleSubmit(obj: any) {
		document.body.dispatchEvent(
			new CustomEvent("avo-submit-newsletter-form", {
				detail: newsletterFormRef.value,
			}),
		);
		sendToSalesCloud(obj);
	}

	async function sendToSalesCloud(userdata: any) {
		if (userdata?.aboList.length) {
			userdata?.aboList.forEach(async (element: any) => {
				let currentAbo = newsletter.value?.filter((item) => item.salescloud_key === element)[0];

				const body = {
					newsletter_meta_data: config.newsletter_meta_data,
					subscriptionSource: props.subscriptionSource,
					currentURL: url?.href,
					...userdata,
					campaignId: currentAbo.campaign_id,
				};

				const { data, error } = await useFetch("/api/user/newsletter", {
					method: "POST",
					body,
				});

				if (data.value === "") {
					newsletterForm.response = {};
					newsletterForm.response.title = "Ihre Anmeldung war erfolgreich";
					newsletterForm.response.message =
						"Danke für Ihre Bestellung, Ihre Anmeldung war erfolgreich! In Kürze erhalten Sie eine Aktivierungsmail. Bitte beachten Sie: Wenn Sie Ihre E-Mail-Adresse schon einmal bei uns bestätigt haben, erhalten Sie keine Aktivierungsmail mehr.";
					newsletterForm.response.status = "success";
				}

				if (error.value !== null) {
					newsletterForm.response = {};
					newsletterForm.response.title = null;
					newsletterForm.response.message = "Ein Fehler ist aufgetreten versuchen Sie es bitte später erneut.";
					newsletterForm.response.status = "error";

					throw new Error("Network response was not OK");
				}
			});
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../storybook/src/assets/css/mixins";
	.u-personal-newsletter {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;

		@include desktop {
			flex-direction: row;
			justify-content: space-around;
		}

		&__content {
			@include desktop {
				padding-inline: var(--space-4);
				flex-grow: 1;
				max-width: 40rem;
				text-align: center;
			}
		}

		&__media {
			display: flex;
			justify-content: center;
			width: 8.625rem;

			img {
				max-width: 100%;
			}

			@include desktop {
				width: 8.875rem;
			}
		}

		&__form {
			padding: 0;
			background-color: transparent;
		}

		@include desktop {
			:deep(.u-newsletter-form__title) {
				margin: auto;
			}

			:deep(.u-newsletter-form__agreement) {
				max-width: 33.125rem;
				margin: auto;
			}
		}

		:deep(.u-newsletter-form__text) {
			font-style: italic;

			@include desktop {
				text-align: center;
			}
		}

		&__interval {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			@include desktop {
				flex-direction: row;
				justify-content: center;
				gap: var(--space-5);
			}

			.u-form-radio {
				display: grid;
				grid-template-columns: 1.25rem auto;
				gap: 0.5rem;

				input {
					appearance: none;
					background-color: #fff;
					margin: 0;
					font: inherit;
					color: var(--color-neutral-20);
					width: 1.25rem;
					height: 1.25rem;
					border: 0.125rem solid currentColor;
					border-radius: 50%;
					transform: translateY(-0.0625rem);
					display: grid;
					place-content: center;

					&:checked {
						color: var(--npg-theme-primary-color);
						border-width: 0.3125rem;
					}
				}

				&--error {
					input {
						border-color: var(--color-secondary-300);
					}
				}
				&--success {
					input {
						border-color: currentColor;
					}
				}
			}
		}
	}
</style>
